import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import clsx from "clsx";
import Link from "next/link";
import React from "react";

import { ProductPrice } from "@/components/Product/components/ProductPrice";
import { PlaceholderImageString, ProductImage } from "@/components/Product/ProductImage";
import { ScrollItem } from "@/components/Scroll/Container";

export const SliderProduct = ({ product, isLastItem }: { product: PricedProduct; isLastItem: boolean }) => {
  const productDetailLink = `/p/${product.handle}`;
  const productThumbnail = product.thumbnail ?? PlaceholderImageString;

  return (
    <ScrollItem
      className={clsx("min-h-[15rem] min-w-[19rem] md:min-w-[22rem] lg:min-h-[25rem] lg:min-w-[25rem] bg-white border border-gray-700 rounded-3xl", {
        "mr-2": isLastItem,
      })}
    >
      {productDetailLink && productThumbnail && (
        <Link href={productDetailLink}>
          <div className="mx-auto">
            <ProductImage
              src={productThumbnail}
              width={200}
              height={1000}
              alt={product.title ?? ""}
              fallbackimage={PlaceholderImageString}
              className="w-[auto] h-[17.5rem] mx-auto object-cover"
            />
          </div>
          <div className="flex flex-row justify-between pt-2 px-4 pb-4">
            <p className="text-base w-full mr-1 self-center">
              {/* Kann für Herrstellernamen genutzt werden */}
              {/*<span className="font-light">{"KODI BASIC "}</span>*/}
              <span className="font-medium">{product.title?.toUpperCase()}</span>
            </p>
            <div className="min-h-[84px] sm:min-h-[88px] lg:min-h-[108px] flex justify-end items-center">
              <ProductPrice product={product} />
            </div>
          </div>
        </Link>
      )}
    </ScrollItem>
  );
};
