"use client";

import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

import Button from "@/components/Form/Button/Button";
import { ArrowDropLeftIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropLeftIcon";
import { ArrowDropRightIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropRightIcon";

interface ScrollContainerInterface {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

export const ScrollItem = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return <div className={`kodi__scroll__item ${className || ""}`}>{children}</div>;
};

export const ScrollContainer = ({ children, fallback }: ScrollContainerInterface) => {
  const [isShowLeftButton, setShowLeftButton] = useState<boolean>(false);
  const [isShowRightButton, setShowRightButton] = useState<boolean>(true);
  const container = useRef<HTMLDivElement | null>(null);
  const [isFallback, setFallback] = useState<boolean>(!container?.current?.children.length);

  useEffect(() => setFallback(!container?.current?.children.length), [children]);

  const scrollX = (cont: HTMLElement, left: boolean) => {
    let scrollWidthEl = 328;
    const elements = cont.getElementsByClassName("kodi__scroll__item");

    if (elements.length >= 1) {
      scrollWidthEl = elements[elements.length - 1].scrollWidth + 10;
    }

    cont.scrollLeft += left ? scrollWidthEl : -scrollWidthEl;
  };

  const scrollInContainerX = (left: boolean) => {
    container.current && scrollX(container.current, left);
  };

  const eventListener = (initial = false) => {
    const el = container.current;

    if (el) {
      const scrollWidth = !initial ? el.scrollWidth - 10 : el.scrollWidth;

      setShowLeftButton(el.scrollLeft >= 10);
      setShowRightButton(el?.scrollLeft + el?.clientWidth <= scrollWidth);
    }
  };

  useEffect(() => {
    const el = container.current;
    if (el) {
      eventListener(true);
      el.addEventListener("scroll", () => eventListener());

      return () => el.removeEventListener("scroll", () => eventListener());
    }
  }, [container.current]);

  return (
    <div className="relative">
      <div className={clsx("flex-start", { hidden: !isShowLeftButton })}>
        <div className="absolute left-2 bottom-1/2 translate-y-3/4">
          <ScrollButton onClick={() => scrollInContainerX(false)}>
            <ArrowDropLeftIcon width="30" height="30" className="relative right-3" fill="#000" />
          </ScrollButton>
        </div>
      </div>
      <div className={clsx("flex-end", { hidden: !isShowRightButton })}>
        <div className="absolute right-2 bottom-1/2 translate-y-3/4">
          <ScrollButton onClick={() => scrollInContainerX(true)}>
            <ArrowDropRightIcon width="30" height="30" className="relative right-3" fill="#000" />
          </ScrollButton>
        </div>
      </div>
      <div ref={container} className="overflow-x-scroll flex scrollbar-width">
        {isFallback && fallback && fallback}
        {children}
      </div>
    </div>
  );
};

const ScrollButton = ({ onClick, children }: { onClick: () => void; children: React.ReactNode }) => {
  return (
    <Button
      className={clsx(
        "p-5 font-medium text-lg uppercase rounded-full bg-white/30 hover:bg-white/50 border border-white",
        "group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none",
        "w-10 h-10 m-auto text-center relative z-20",
      )}
      rounded
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
