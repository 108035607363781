"use client";

import { useCart } from "medusa-react";
import React, { useEffect, useState } from "react";

import { SearchParamsInterface } from "@/app/(main)/(padding)/wochenangebote/page";
import Breadcrumb, { BreadcrumbItem } from "@/components/Breadcrumb";
import { SortButton } from "@/components/Button/SortButton";
import { Categories } from "@/components/pages/category";
import { ProductListingTitle } from "@/components/pages/category/Products";
import { ThemeWorldInterface } from "@/components/pages/themeWorld/index";
import { getWeek, ProvideProductCardIds } from "@/components/pages/weeklyOffers/Offers";
import { Pagination, ViewedProducts } from "@/components/Pagination";
import { requestThemeProductIds, ThemeProductInterface } from "@/components/Slider/Product/components/Types/ThemeWorld";
import { usePagination } from "@/lib/hooks/use-pagination";

export const WEEKLY_OFFER_HANDLE = { name: "Wochenangebote", handle: "/wochenangebote" };

export const ThemeWorldProducts = ({
  searchParams,
  medusa_key,
  themeWorlds,
  currentThemeWorld,
}: SearchParamsInterface & { title: string; medusa_key: string; themeWorlds: ThemeWorldInterface[]; currentThemeWorld: ThemeWorldInterface }) => {
  const { getLimit, getOffset, getPageNumber } = usePagination(21);
  const [sort, setSort] = useState<string>("");
  const { cart } = useCart();
  const [productsIds, setProductsIds] = useState<ThemeProductInterface>({ totals: 0, ids: [] });

  useEffect(() => {
    const week = getWeek();

    requestThemeProductIds(medusa_key, week, getOffset(), getLimit())
      .then(({ ids, totals }) => {
        setProductsIds({ totals: totals, ids: ids });
      })
      .catch((err) => console.error(err));
  }, [getLimit(), getOffset(), searchParams, medusa_key]);

  return (
    <>
      <div className="mb-10 pl-4 lg:block hidden">
        <ThemeWorldBreadCrumbs themeWorld={currentThemeWorld} />
      </div>

      <div className="grid grid-cols-12">
        <div className="col-span-12 lg:col-span-3 mt-10 px-4 lg:block hidden">
          <Categories exactHandle={true} categories={provideThemeWorld(themeWorlds)} />
        </div>
        <div className="col-span-12 lg:col-span-9 px-4">
          <ProductListingTitle />

          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-12 mt-3 border-y border-gray-300">
              <SortButton setSort={setSort} />
            </div>

            <ProvideProductCardIds ids={productsIds.ids} regionId={cart?.region_id} sort={sort} />
          </div>

          <div className="flex justify-between mt-14">
            <div>
              <ViewedProducts limit={getLimit()} pageNumber={getPageNumber()} totals={productsIds.totals ?? 0} />
            </div>
            <Pagination limit={getLimit()} totals={productsIds.totals} />
          </div>
        </div>
      </div>
    </>
  );
};

export const ThemeWorldBreadCrumbs = ({ themeWorld, isWeeklyOffer }: { themeWorld: ThemeWorldInterface; isWeeklyOffer?: boolean }) => {
  const data = [
    { name: "Home", link: "/", index: 0 },
    { ...WEEKLY_OFFER_HANDLE, index: 1 },
  ] as BreadcrumbItem[];

  if (!isWeeklyOffer) {
    data.push({ ...prepareThemeWorld(themeWorld), index: 2 });
  }

  return (
    <div>
      <Breadcrumb items={data} />
    </div>
  );
};

const prepareThemeWorld = (themeWorld: ThemeWorldInterface) => {
  return {
    name: themeWorld.title,
    link: themeWorld.url_alias.data.attributes.url_path,
  };
};

export const provideThemeWorld = (themeWorlds: ThemeWorldInterface[]) => {
  const worlds = provideThemeWorldsCategories(themeWorlds);

  worlds.unshift(WEEKLY_OFFER_HANDLE);
  return worlds;
};

export const provideThemeWorldsCategories = (themeWorlds: ThemeWorldInterface[]) => {
  return themeWorlds.map((theme) => ({
    name: theme.title,
    handle: theme.url_alias.data.attributes.url_path,
  }));
};
